// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDX28708b3xtA602kmEyAb4ECoWsBMZcYQ",
    authDomain: "latam-eventos.firebaseapp.com",
    databaseURL: "https://latam-eventos.firebaseio.com",
    projectId: "latam-eventos",
    storageBucket: "latam-eventos.appspot.com",
    messagingSenderId: "703692565667",
    appId: "1:703692565667:web:89376f92a111363b692316",
    measurementId: "G-8LGQY3PS2J"
};

// Initialize Firebase
const ap = initializeApp(firebaseConfig);
const db = getDatabase(ap);
export { db };