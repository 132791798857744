// Este es un provider que obtiene el estado inicial de la aplicación desde el localStorage y
// lo guarda en el estado de la aplicación.

import React, { createContext } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

const StateContext = createContext();

const StateProvider = ({ children }) => {
    const [state, setState] = useLocalStorage('state', {
        User: null,
        isAuthenticated: false,
        Cursos: null
    });

    return (
        <StateContext.Provider value={{ state, setState }}>
            {children}
        </StateContext.Provider>
    );
}

export { StateProvider, StateContext };