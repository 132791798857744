import React from "react";
import { Container, Row, Col, Card, ListGroup, ListGroupItem } from "react-bootstrap";


// Services
import config from "../../services/config";
import { getCourse, marcarAsistencia } from "../../services/Api";

// Navigation
import { useNavigate, Link } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

// Chat
import { ChatWindow } from "./ChatWindow";


export const Watch = ({ state, setState }) => {
    const navigate = useNavigate();

    const id = window.location.href.split('/').pop();
    const [curso, setCurso] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const handleStreaming = () => {
        // el curso.Streaming puede ser un iframe o un video de youtube, así que renderizamos dependiendo del caso
        // si es un iframe
        if (curso.Streaming.includes('<iframe')) {
            return <div dangerouslySetInnerHTML={{ __html: curso.Streaming }} />;
        }
        // si es un video de youtube pero no en un iframe
        if (curso.Streaming.includes('youtube')) {
            return (
                <>
                    <div style={{ position: 'relative' }}>
                        <iframe
                            width="100%"
                            height="500"
                            src={curso.Streaming}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                            sandbox="allow-forms allow-scripts allow-same-origin allow-presentation"
                            referrerPolicy="strict-origin-when-cross-origin">
                        </iframe>
                        <div style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '20%',
                            zIndex: '1',
                        }} />
                        <div style={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            width: '80%',
                            height: '20%',
                            zIndex: '1',
                        }} />
                    </div>
                </>
            );
        }
    };

    const handleAsistencias = () => {
        // Verificamos en state.User si el usuario tiene asistencias, si tiene buscamos las correspondientes al curso actual y las mostramos
        // Si no tiene asistencias, mostramos un mensaje de que no tiene asistencias
        if (state.User.Asistencia !== undefined) {
            // state.User.Asistencia es un objeto, y necesitamos un array para poder recorrerlo
            const asistencias = Object.values(state.User.Asistencia).filter(asistencia => asistencia.Curso === id);

            if (asistencias.length > 0) {
                return asistencias.map((asistencia, index) => {
                    return (
                        <ListGroupItem key={index}>
                            {asistencia.Fecha}
                        </ListGroupItem>
                    );
                });
            }
        } else {
            return <ListGroupItem>No hay asistencias</ListGroupItem>;
        }
    };

    const marcarMiAsistencia = async () => {
        const response = await marcarAsistencia(config.token, id, state.User.key);
        let fecha_hora_actual = new Date();
        fecha_hora_actual = fecha_hora_actual.toISOString().split('T');

        // Si no existe state.User.Asistencia, lo creamos como un array vacío
        let asistencias = state.User.Asistencia || {};

        asistencias[response.id] = {
            Curso: response.Curso,
            Fecha: fecha_hora_actual,
        };

        setState({
            ...state,
            User: {
                ...state.User,
                Asistencia: asistencias,
            }
        });
    };

    const handleMateriales = () => {
        if(curso !== null && curso?.Material !== null && curso?.Material !== undefined) {
            // cruso.Material es un objeto, y necesitamos un array para poder recorrerlo
            const materiales = Object.values(curso.Material);
            return materiales.map((material, index) => {
                return (
                    <ListGroupItem key={index}>
                        <a href={material.Link} target="_blank" rel="noreferrer">{material.Nombre}</a>
                    </ListGroupItem>
                );
            });
        }
    };

    React.useEffect(() => {
        if (state.User.Pago === false) navigate('/home');

        const fetchCourse = async () => {
            const response = await getCourse(config.token, id);
            setCurso(response);
            setLoading(false);
        }

        fetchCourse();

        const intervalId = setInterval(() => {
            marcarMiAsistencia();
        }, 60 * 1000 * 5);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <Container>
                <Row>
                    <Col md={8} className="mt-4 mb-2">
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    <Row>
                                        <Col md={2}>
                                            <Link to="/home">
                                                <FontAwesomeIcon icon={faArrowLeftLong} /> Volver
                                            </Link>
                                        </Col>
                                        <Col md={10}>
                                            <h3>
                                                <FontAwesomeIcon icon={faVideo} /> {loading ? 'Cargando...' : curso.Nombre}
                                            </h3>
                                        </Col>
                                    </Row>
                                </Card.Title>
                                <div className="text-center">
                                    {loading ? 'Cargando...' : handleStreaming()}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mt-4 mb-4">
                        <Card>
                            <Card.Body>
                                <ChatWindow user={state.User?.Correo} channel={id} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="mt-4 mb-4">
                        <img src={curso?.Banner} alt={curso?.Nombre} className="img-fluid" />
                    </Col>
                    <Col md={6} className="mt-4 mb-4">
                        <h5>Materiales de apoyo</h5>
                        <Card>
                            <Card.Body>
                            <ListGroup>
                                {handleMateriales()}
                            </ListGroup>
                            </Card.Body>
                        </Card>
                        
                    </Col>
                    <Col md={3}className="mt-4 mb-4">
                        <h5>Asistencias</h5>
                        <ListGroup className="lista-asistencias">
                            {handleAsistencias()}
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        </>
    );
};