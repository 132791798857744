import React from 'react';
import { Navigate } from 'react-router-dom';
import NavBar from './NavBar';

const PrivateRoute = ({ children, state }) => {
    const isAuthenticated = state.isAuthenticated;

    if(isAuthenticated){
        return (
            <>
                <NavBar state={state} />
                <div id="main-content">
                    {children}
                </div>
            </>
        );
    }

    return <Navigate to="/login" />;
};

export default PrivateRoute;