import React from 'react';
import { Col, Card, Button } from 'react-bootstrap';

// Services
import config from "../../services/config";
import { getCourse } from "../../services/Api";

export const Curso = ({ curso }) => {

    const handleVercurso = () => {
        let fecha_actual = new Date();
        let fecha_inicio = new Date(curso.Fecha_Inicio);
        // fecha inicio en hora cero
        fecha_inicio = fecha_inicio.setHours(0,0,0,0);
        let fecha_fin = new Date(curso.Fecha_Fin);
        fecha_fin = fecha_fin.setHours(23,59,59,999);
        
        if(fecha_actual >= fecha_inicio && fecha_actual <= fecha_fin){
            return <Card.Link href={"#/course/" + curso?.id}>Ver curso</Card.Link>;
        } else {
            return <Button variant="secondary" disabled>Ver curso</Button>;
        }
    };

    return (
        <Col md={3} className='mt-4 mb-4'>
            <Card>
                <Card.Img variant="left" src={curso?.Banner} />
                <Card.Body>
                    <Card.Title>{curso?.Nombre}</Card.Title>
                    <Card.Text>
                        Fecha de inicio: {curso?.Fecha_Inicio}
                    </Card.Text>
                    <Card.Text>
                        Fecha de fin: {curso?.Fecha_Fin}
                    </Card.Text>
                    <hr />
                    <div className='text-end'>
                        {handleVercurso()}
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
};