import React from "react";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";

// Services
import config from "../../services/config";
import { getCourse } from "../../services/Api";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Curso } from "./Curso";

export const Home = ({ state, setState }) => {
    React.useEffect(() => {
        handleCursos();
    }, []);

    async function handleCursos() {
        if (state.User.Cursos !== undefined && state.Cursos === null) {
            let cursos = [];
            for (let item of state.User.Cursos) {
                const result = await getCourse(config.token, item.Curso);
                cursos.push(result);
            }
            setState({
                ...state,
                Cursos: cursos
            });
        }
    };

    const showCursos = () => {
        if (state.Cursos !== null && state.Cursos.length > 0) {
            // Verificamos que el curso esté impartiendose y que el usuario esté en modo Pago=true
            return state.Cursos.map((curso, index) => {
                return <Curso key={index} curso={curso} />
            });
        }
        return <Col>
            <Alert variant="info" dismissible={true}>
                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                No tienes cursos asignados
            </Alert>
        </Col>;
    };

    return (
        <>
            <Container>
                <Row>
                    <Col className="mt-4 mb-2">
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    <h1>
                                        <FontAwesomeIcon icon={faUserCircle} /> Bienvenido
                                    </h1>
                                </Card.Title>
                                <h3>¡Hola {state.User.Nombre}!</h3>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {showCursos()}
                </Row>
            </Container>
        </>
    );
};