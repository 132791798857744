import React, { useState, useEffect, useRef } from "react";
import { db } from "../../services/firebaseConfig";
import { ref, onChildAdded } from "firebase/database";
import config from "../../services/config";
import { Card, Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";

let socket;

function connectWebSocket(addMessage) {
    socket = new WebSocket(config.socket);

    socket.onopen = () => {
        console.log('Conexión establecida');
    };

    socket.onmessage = (event) => {
        event.data.text().then((messageText) => {
            const message = JSON.parse(messageText);
            console.log('Received from server:', message);
            addMessage(message);
        }).catch((error) => {
            console.error('Error parsing message:', error);
        });
    };

    socket.onclose = () => {
        console.log('Conexión cerrada');
        setTimeout(() => {
            connectWebSocket(addMessage);
        }, 1000);
    };

    socket.onerror = (error) => {
        console.error('Error:', error);
        socket.close();
    };
}

export const ChatWindow = ({ user, channel }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const messageTimestamps = useRef(new Set());

    const addMessage = (message) => {
        if (!messageTimestamps.current.has(message.datetime)) {
            messageTimestamps.current.add(message.datetime);
            setMessages((prevMessages) => [...prevMessages, message]);
        }
    };

    useEffect(() => {
        connectWebSocket(addMessage);

        const firebaseRef = ref(db, `IRC/${channel}`);
        onChildAdded(firebaseRef, (snapshot) => {
            const message = snapshot.val();
            console.log('Received from Firebase:', message); // Debug log
            addMessage(message);
        });

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [channel]);

    const sendMessage = () => {
        if (input.trim() && socket.readyState === WebSocket.OPEN) {
            const fecha_hora = new Date().toISOString();
            const message = { usuario: user, message: input, channel: channel, datetime: fecha_hora };
            console.log('Sending message:', message); // Debug log
            socket.send(JSON.stringify(message));
            setInput('');
        } else {
            console.log('WebSocket is not open. ReadyState: ', socket.readyState);
        }
    };

    useEffect(() => {
        // Lleva el scroll al final del chat
        const chatWindow = document.querySelector('.chat-window');
        chatWindow.scrollTop = chatWindow.scrollHeight;
    }, [messages]);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="mb-4">
                        <div className="chat-window">
                            {messages.map((msg, index) => (

                                <Card key={'msg_' + index} className="mb-2">
                                    <Card.Body>
                                        <Card.Text className="message-text"><b>{msg.usuario}:</b>&nbsp;&nbsp;{msg.message}</Card.Text>
                                    </Card.Body>
                                </Card>

                            ))}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup className="mb-3" size="lg">
                            <Form.Control
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Escribe tu mensaje"
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        sendMessage();
                                    }
                                }}
                            />
                            <Button variant="outline-primary" onClick={sendMessage}>Enviar</Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
