import { Container, Navbar, NavDropdown } from "react-bootstrap";

// Navigation
import { Link } from "react-router-dom";

const NavBar = ({ state }) => {
    const userData = state.User;
    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="#/home">e - Learning</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <NavDropdown title={userData.Correo} id="navbarScrollingDropdown">
                        <NavDropdown.Item>
                            <Link to="/profile" className="text-dark">
                                Perfil
                            </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/logout" className="text-dark">
                                Cerrar Sesión
                            </Link>
                        </NavDropdown.Item>
                    </NavDropdown>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;