import React from 'react';
import { StateProvider, StateContext } from './providers/StateProvider';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import { Home } from './modules/system/Home';
import { HomeTeacher } from './modules/system/HomeTeacher';
import { CursoTeacher } from './modules/system/CursoTeacher';
import { Watch } from './modules/system/Watch';
import { Perfil } from './modules/system/Perfil';

import { Login } from './modules/authentication/Login';
import { Logout } from './modules/authentication/Logout';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <StateProvider>
      <StateContext.Consumer>
        {({ state, setState }) => (
          <HashRouter>
            <Routes>
              <Route path="/" element={ <Navigate to="/login"/> } />
              <Route path="login" element={ <Login state={state} setState={setState}/> } />
              <Route path="home" element={
                <PrivateRoute state={state}>
                  {(state.User !== null && state.User.Profesor !== undefined && state.User.Profesor !== null && state.User.Profesor) ? <HomeTeacher state={state} setState={setState}/> : <Home state={state} setState={setState}/> }
                </PrivateRoute>
              } />
              <Route path="course/:id" element={
                <PrivateRoute state={state}>
                  {(state.User !== null && state.User.Profesor !== undefined && state.User.Profesor !== null && state.User.Profesor) ? <CursoTeacher state={state} setState={setState}/> : <Watch state={state} setState={setState}/> }
                </PrivateRoute>
              } />
              <Route path="profile" element={
                <PrivateRoute state={state}>
                  <Perfil state={state} setState={setState}/>
                </PrivateRoute>
              } />
              <Route path="logout" element={
                <PrivateRoute state={state}>
                  <Logout state={state} setState={setState}/>
                </PrivateRoute>
              } />

            </Routes>
          </HashRouter>
        )}
      </StateContext.Consumer>
    </StateProvider>
  );
}

export default App;
