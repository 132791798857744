import React from 'react';

// Servicios
import config from '../../services/config';
import { getSlogan, getLogo, makeLogin } from '../../services/Api';

// Bootstrap
import { Container, Row, Col, Button, Form, Card, Alert } from 'react-bootstrap';

// Navegacion
import { useNavigate } from 'react-router-dom';

export const Login = ({ state, setState }) => {
    const navigate = useNavigate();

    const [logo, setLogo] = React.useState('');
    const [slogan, setSlogan] = React.useState('');
    const [error, setError] = React.useState(false);

    const [correo, setCorreo] = React.useState('');
    const [telefono, setTelefono] = React.useState('');
    const [tipo_login, setTipo_login] = React.useState('Estudiante');
    const [contrasena, setContrasena] = React.useState('Estudiante');

    React.useEffect(() => {
        const fetchLogo = async () => {
            const response = await getLogo(config.token);
            setLogo(response);
        }
    
        const fetchSlogan = async () => {
            const response = await getSlogan(config.token);
            setSlogan(response);
        }

        fetchLogo();
        fetchSlogan();
    } , []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(false);
        let Contrasena = tipo_login === 'Catedrático' ? contrasena : telefono;
        const response = await makeLogin(tipo_login, correo, Contrasena);
        if(response){
            setState({ ...state, User: response, isAuthenticated: true});
            navigate('/home');
        } else {
            setError(true);
        }
    };

    const UserOrPasswordIncorrect = () => {
        return (
            <Alert variant='danger' dismissible='true'>
                Usuario o contraseña incorrectos
            </Alert>
        );
    }

    return (
        <Container>
            <Row className='justify-content-md-center'>
                <Col lg={3} md={6} xs={12} className='mt-4'>
                    <Card>
                        <Card.Img variant="top" src={logo} />
                        <Card.Body>
                            <Card.Title className='text-center'>
                                <h3>{slogan}</h3>
                            </Card.Title>
                            <Form>
                                <Form.Group controlId="formBasicEmail" className='mb-1'>
                                    <Form.Label>Tipo de usuario</Form.Label>
                                    <Form.Control as="select" value={tipo_login} onChange={(e) => setTipo_login(e.target.value)}>
                                        <option>Estudiante</option>
                                        <option>Catedrático</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail" className='mb-1'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Introduce tu email" value={correo} onChange={(e) => setCorreo(e.target.value)} />
                                </Form.Group>
                                {tipo_login === 'Catedrático' && <Form.Group controlId="formBasicPassword" className='mb-1'>
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control type="password" placeholder="Contraseña" value={contrasena} onChange={(e) => setContrasena(e.target.value)} />
                                </Form.Group>}
                                {tipo_login === 'Estudiante' && <Form.Group controlId="formBasicPassword" className='mb-1'>
                                    <Form.Label>Teléfono</Form.Label>
                                    <Form.Control type="text" placeholder="Teléfono" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                </Form.Group> }
                                <hr/>
                                <Button variant="primary" type="submit" onClick={handleLogin}>
                                    Iniciar Sesión
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <br/>
                    {error && <UserOrPasswordIncorrect/>}
                </Col>
            </Row>
        </Container>
    );
};