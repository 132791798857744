import React from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

// servicios
import config from "../../services/config";
import { updateStudent } from "../../services/Api";

export const Perfil = ({state, setState}) => {

    const [nombre, setNombre] = React.useState(state.User.Nombre);
    const [titulo, setTitulo] = React.useState(state.User.Titulo);
    const [colegiado, setColegiado] = React.useState(state.User.Colegiado);
    const [actualizado, setActualizado] = React.useState(false);

    const handleNombre = (e) => {
        setNombre(e.target.value);
    }

    const handleTitulo = (e) => {
        setTitulo(e.target.value);
    }

    const handleColegiado = (e) => {
        setColegiado(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            id: state.User.key,
            Nombre: nombre,
            Titulo: titulo,
            Colegiado: colegiado
        }
        const response = await updateStudent(config.token, data);
        if (response) {
            setState({
                ...state,
                User: {
                    ...state.User,
                    Nombre: nombre,
                    Titulo: titulo,
                    Colegiado: colegiado
                }
            });
            setActualizado(true);
        }
    }

    React.useEffect(() => {
        if(!actualizado) return;
        setTimeout(() => {
            setActualizado(false);
        }, 3000);
    }, [actualizado]);

    return (
        <>
            <Container>
                <Row>
                    <Col className="mt-4 mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    <h1><FontAwesomeIcon icon={faUserCircle} /> Perfil de Usuario</h1>
                                </Card.Title>
                                <Card.Text>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="nombre">Nombre</label>
                                            <input type="text" className="form-control" id="nombre" value={nombre} onChange={handleNombre} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="titulo">Título</label>
                                            <input type="text" className="form-control" id="titulo" value={titulo} onChange={handleTitulo} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="colegiado">Colegiado</label>
                                            <input type="text" className="form-control" id="colegiado" value={colegiado} onChange={handleColegiado} />
                                        </div>
                                        <hr/>
                                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Guardar</button>
                                    </form>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {actualizado ? <div className="alert alert-success mt-4" role="alert">Datos actualizados correctamente</div> : null}
                    </Col>
                </Row>
            </Container>
        </>
    );

};