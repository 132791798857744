import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Logout = ({ state, setState }) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        setState({ ...state, User: {}, isAuthenticated: false, Cursos: null });
    }, []);

    return navigate('/login');
};