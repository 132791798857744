import React from "react";

// Bootstrap
import { Container, Row, Col, Card, Button, Form, InputGroup, Alert, ListGroup, Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import { ChatWindow } from "./ChatWindow";

// Services
import config from "../../services/config";
import { getCourse, upload, setNewMaterial, deleteMaterial, updateCourse } from "../../services/Api";

// Navigation
import { useNavigate, Link } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faBook, faTrash } from "@fortawesome/free-solid-svg-icons";

// El profesor puede actualizar la url de Streaming
// Además podrá agregar contenido de apoyo para los estudiantes

export const CursoTeacher = ({ state, setState }) => {
    const navigate = useNavigate();

    const id = window.location.href.split('/').pop();

    const [curso, setCurso] = React.useState(null);
    const [materiales, setMateriales] = React.useState([]);
    const [tipoMaterial, setTipoMaterial] = React.useState('Tipo de material'); // [archivo, link
    const [nombreMaterial, setNombreMaterial] = React.useState('');
    const [nuevoMaterial, setNuevoMaterial] = React.useState('');
    const [messageUpload, setMessageUpload] = React.useState(null);
    const [uploadButtonStatus, setUploadButtonStatus] = React.useState(false); // [true, false
    const [loading, setLoading] = React.useState(false);
    const [mensajeSave, setMensajeSave] = React.useState(null); // [true, false

    React.useEffect(() => {
        const fetchCourse = async () => {
            const response = await getCourse(config.token, id);
            setCurso(response);
            if (response.Material !== undefined) {
                setMateriales(response.Material);
            }
        }

        fetchCourse();
    }, []);

    React.useEffect(() => {
        if (nuevoMaterial !== '') {
            setUploadButtonStatus(false);
        } else {
            setUploadButtonStatus(true);
        }
    }, [nuevoMaterial]);

    const handleStreamingLink = (e) => {
        setCurso({ ...curso, Streaming: e.target.value });
    };

    const handleTipoMaterial = () => {
        if(tipoMaterial === 'Archivo') {
            return (
                <Form.Group>
                    <input type="file" onChange={(e) => setNuevoMaterial(e.target.files[0])} />
                </Form.Group>
            );
        } else if(tipoMaterial === 'Link') {
            return (
                <Form.Group>
                    <Form.Control placeholder="Link del material" value={nuevoMaterial} onChange={(e) => setNuevoMaterial(e.target.value)} />
                </Form.Group>
            );
        } else {
            return null;
        }
    };


    const handleUpload = async (e) => {
        e.preventDefault();
        setLoading(true);
        let url = null;

        if (tipoMaterial === 'Archivo' && nuevoMaterial !== null) {
            const file = nuevoMaterial;
            url = await upload(file);
        } else if (tipoMaterial === 'Link') {
            url = nuevoMaterial;
        }

        if (url !== null) {
            let newMaterial = {
                Nombre: nombreMaterial,
                Link: url
            };
            const response = await setNewMaterial(config.token, id, newMaterial);
            if(response !== undefined){
                setMateriales({ ...materiales, [response.id]: newMaterial });
                setMessageUpload(<><br/><Alert variant="success" dismissible={true}>Material agregado correctamente</Alert></>);
            } else {
                setMessageUpload(<><br/><Alert variant="danger" dismissible={true}>Error al subir el material</Alert></>);
            }
        } else {
            setMessageUpload(<><br/><Alert variant="danger" dismissible={true}>Error al subir el material</Alert></>);
        }
        setLoading(false);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        let esteCurso = {
            id: curso.id,
            Streaming: curso.Streaming
        };
        const response = await updateCourse(config.token, esteCurso);
        if(response !== undefined){
            setMensajeSave(<><br/><Alert variant="success" dismissible={true}>Curso actualizado correctamente</Alert></>);
        } else {
            setMensajeSave(<><br/><Alert variant="danger" dismissible={true}>Error al actualizar el curso</Alert></>);
        }
    };

    const handleViewLink = (e) => {
        e.preventDefault();
        // abrir en una nueva pestaña nueva el link de streaming
        let win = window.open(curso.Streaming, '_blank');
        win.focus();

        console.log('ver');
    };

    const handleDelete = async (material_id) => {
        const response = await deleteMaterial(config.token, id, material_id);
        if(response !== undefined){
            const materialesActualizados = { ...materiales };
            delete materialesActualizados[material_id];
            setMateriales(materialesActualizados);
        }
    };

    return (
        <>
            <Container>
                <Row>
                    <Col className="mt-4 mb-2">
                        <Row>
                            <Col md={2}>
                                <Link to="/home">
                                    <FontAwesomeIcon icon={faArrowLeftLong} /> Volver
                                </Link>
                            </Col>
                            <Col md={10}>
                                <h3>
                                    <FontAwesomeIcon icon={faBook} /> {curso === null ? 'Cargando...' : curso.Nombre}
                                </h3>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-4">
                        <Card>
                            <Card.Header>
                                <h3>{curso !== null ? curso.Nombre : 'Cargando...'}</h3>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Url del streaming embedido"
                                                aria-label="Url del streaming embedido"
                                                aria-describedby="url del streaming embedido"
                                                value={curso === null ? '' : curso.Streaming}
                                                onChange={(e) => handleStreamingLink(e)}
                                            />
                                            <Button variant="outline-secondary" id="button-addon2" onClick={handleViewLink}>
                                                Ver
                                            </Button>
                                        </InputGroup>
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleSave}>
                                        Guardar
                                    </Button>
                                    {mensajeSave}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Card>
                            <Card.Header>
                                <h3>Material de apoyo</h3>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col md={10} className="mb-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Nombre del archivo</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control value={nombreMaterial} onChange={(e) => setNombreMaterial(e.target.value)} aria-label="Nombre del material" />
                                                    <DropdownButton
                                                        variant="outline-secondary"
                                                        title={tipoMaterial}
                                                        id="input-group-dropdown-2"
                                                        align="end"
                                                    >
                                                        <Dropdown.Item onClick={(e) => setTipoMaterial('Archivo')}>Archivo</Dropdown.Item>
                                                        <Dropdown.Item onClick={(e) => setTipoMaterial('Link')}>Link</Dropdown.Item>
                                                    </DropdownButton>
                                                </InputGroup>
                                            </Form.Group>
                                            {handleTipoMaterial()}
                                        </Col>
                                        <Col md={2} className="text-end">
                                            <Button variant="primary" onClick={handleUpload} disabled={uploadButtonStatus}>
                                                Subir&nbsp;
                                                {loading ? <Spinner animation="border" size="sm" /> : null}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                                {messageUpload}
                            </Card.Body>
                            <Card.Footer>
                                <h4>Lista de materiales cargados</h4>
                                <ListGroup as="ul" id="lista_materiales">
                                    {(materiales !== null) ? (
                                        Object.keys(materiales).map((key) => {
                                            return (
                                                <Archivo key={key} id={key} archivo={materiales[key]} handleDelete={handleDelete} />
                                            );
                                        })
                                    ) : (
                                        <Alert variant="info">No hay materiales de apoyo</Alert>
                                    )}
                                </ListGroup>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <ChatWindow user={state.User?.Correo} channel={id} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );

};

const Archivo = ({ id, archivo, handleDelete }) => {
    const { Nombre, Link } = archivo;

    return (
        <ListGroup.Item as="li"
            className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
                <div className="fw-bold"><a href={Link} target="_blank">{Nombre}</a></div>
                {Link}
            </div>
            <Button variant="danger" onClick={() => handleDelete(id)}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        </ListGroup.Item>
    );
};